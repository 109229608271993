import React, {useEffect, useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const PlayView = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
        document.title = "Hotel | Habbo"
      if(props.account === null) navigate("/index", { replace: true });
  }, [])


  const location = useLocation();

  const [clientInit, setclientInit] = useState(false);
  const [visibility, setVisibility] = useState<DocumentVisibilityState>("visible")


  useEffect(() => {
      if (location.pathname === "/play") {
          setclientInit(true);
          setVisibility("visible");
      }
      else setVisibility("hidden");

  }, [location]);

  const getSrcIframe = (sso) => "https://nitro.habbo.cc/index.html?sso=" + sso;
  return (
    <>
{props.account !== null && clientInit &&
                <>
                    {visibility === "visible" && <Link className='back-button btn-sm text-center' to="/me"></Link>}

                    <div style={{overflow: "hidden"}}>
                        <iframe allow="
                            accelerometer *; 
                            ambient-light-sensor *; 
                            autoplay *; 
                            camera *; 
                            clipboard-read *; 
                            clipboard-write *; 
                            encrypted-media *; 
                            fullscreen *; 
                            geolocation *; 
                            gyroscope *; 
                            magnetometer *; 
                            microphone *; 
                            midi *; 
                            payment *; 
                            picture-in-picture *; 
                            screen-wake-lock *; 
                            speaker *; 
                            sync-xhr *; 
                            usb *; 
                            web-share *; 
                            vibrate *; 
                            vr *"
                        className='animate__animated animate__fadeIn animate__slower' 
                        src={getSrcIframe(window.localStorage.getItem("kubboch-sto"))} 
                        style={{ 
                            position: 'fixed', 
                            top: 0, 
                            left: 0, 
                            right: 0, 
                            bottom: 0, 
                            width: "100vw", 
                            height: "100vh", 
                            border: "none", 
                            margin: 0, 
                            padding: 0, 
                            overflow: "hidden !important", 
                            zIndex: 999999, 
                            visibility: visibility
                            
                         }}
                        >
                        </iframe>
                    </div>
                    </>
            }
    </>
  )
}

export default PlayView
