import React, { useEffect, useState } from 'react'

import "./Catalog.scss";
import { BATTLEPASS_RARE, BONZEN_RARE, CASH_RARE, CASINO_RARE, CATEGORY_CRACKABLE, CATEGORY_CUSTOM, CATEGORY_LTD, CATEGORY_RARE, EVENTPUNKTE_RARE, GIVEAWAY_RARE, SCHWARZMARKT_RARE, SONSTIGE_RARE, WOCHENRARE } from './Category';
import CatalogItemView from './views/CatalogItemView';
import useWebSocket from 'react-use-websocket';
import { NotificationManager } from 'react-notifications';

const CatalogView = (props: any) => {

    const [items, setItems] = useState<any[]>([]);
    const [category, setCategory] = useState(WOCHENRARE);
    const [search, setSearch] = useState("");

    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://habbocc.hanarchy.net/e");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "updateCatalogPriceResponse" && message.data !== null) {
                const status: string = message.data.data;

                if (status === "Ok") {
                    NotificationManager.success("Aktualisierter Preis", "Ok");
                    fetchData();
                }
                else if (status === "Error") NotificationManager.error("Fehler beim Aktualisieren des Preises", "Error");
            }
        }
    }, [lastMessage, setMessageHistory]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://youtube.habbo.cc/Rare/get');
            const jsonData = await response.json();
            setItems(jsonData);
        } catch (error) { }
    };

    return (
        <div className="max-w-6xl mx-auto grid grid-cols-12 mt-5 gap-4 animate__animated animate__fadeIn animate__slower">
            <div className="col-span-12 p-1 bg-[#f9f9f9]">
                <div className="h-32 bg-white whiteBackground flex flex-col items-center justify-center relative overflow-hidden">
                    <div className="vipImageLeft hidden md:block"></div>
                    <p className='text-4xl uppercase poppins-bold text-quarternary tracking-widest mt-2 text-center md:text-start z-10'>Preisliste</p>
                    <div className="w-96 h-[2px] bg-black"></div>
                    <p className='text-quarternary poppins-bold text-center md:text-start z-10'>Hier ist deine Preisliste für<span className='text-[#5aa488] mr-1 ml-1'>HabboCC</span></p>
                </div>
            </div>
            <div className="col-span-12">
                <input className='jeInput' type="text" placeholder='Nach Rare suchen..' onChange={(e) => setSearch(e.target.value)} value={search} />
            </div>
            {items.length != 0 && search.length == 0 ?
                    items.filter(item => item.category === category).map(i =>
                        <CatalogItemView item={i} canEdit={props.account != null ? props.account.editPrice : false} />
                    )
                    :
                    items.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).map(i =>
                        <CatalogItemView item={i} canEdit={props.account != null ? props.account.editPrice : false} />
                    )
            }
        </div>
    )
}

export default CatalogView