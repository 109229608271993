import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getBadgeUrl, getProfileUrl, LocalizeText } from '../../Globals';

import LoadingView from '../loading/LoadingView';
import { StaffComposer } from '../../packets/StaffComposer';
import TeamView from './TeamView';
import "./Team.scss";


const EhrenamtPage = (props) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://habbocc.hanarchy.net/e");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
  const navigate = useNavigate();
  const params = useParams();

  const [staffs, setStaffs] = useState<any[]>([]);
  const [ranks, setRanks] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState("all");

  const selectSection = (section) => {
    if (section === null) setSection("all");
    else setSection(section);
  };

  var isLoaded = false;
  useEffect(() => {
    if (isLoaded) return;
    isLoaded = true;

    if (params === null || params.type === null) navigate("/me", { replace: true });
    selectSection(params.type);
    sendMessage(JSON.stringify(new StaffComposer()))
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);

      if (message.header === "staff" && message.data !== null) {
        setStaffs(JSON.parse(message.data.staffs));
        setRanks(JSON.parse(message.data.ranks));
      }
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
  }, [/*  */]);

  useEffect(() => {
    document.title = "Staff | Habbo"
    if (ranks !== null) {
      if (!(section === "all" || ranks.filter(obj => obj.name === section).length === 1)) navigate("/me", { replace: true });
    }
    if (staffs !== null && ranks !== null) setLoading(false)
  }, [staffs, ranks])

  const changeSection = (section) => {
    setLoading(true);
    navigate("/ehrenamt/" + section, { replace: true });
    setSection(section);
    setLoading(false);
  };

  const getImagerLook = (look) => "https://nitro-imager.habbo.gt/?figure=" + look + "&direction=2&head_direction=3&gesture=sml&size=l&img_format=gif";



  return (
    <>
      {loading ? (
        <LoadingView />
      ) : (
        <>
          {/* */}
          <div className="max-w-6xl grid grid-cols-12 w-full gap-4 mx-auto animate__animated animate__fadeIn animate__slower mt-5">

            {''}
            <div className="col-span-12 flex flex-col gap-5 bg-[#f9f9f9] p-0.5 pb-3">
            <div className="h-32 bg-white whiteBackground flex flex-col items-center justify-center relative overflow-hidden -mb-3">
                <div className="habboStaffImage hidden md:block"></div>
                <p className='text-4xl uppercase poppins-bold text-quarternary tracking-widest mt-2 text-center md:text-start'>Habbo Mitarbeiter</p>
                <div className="w-96 h-[2px] bg-black"></div>
                <p className='text-quarternary poppins-bold text-center md:text-start'>DIESE GESICHTER STECKEN HINTER DEM HOTEL!</p>
              </div>
              {section === "all" ? (
                <>
                  {ranks.filter(obj => obj.name === "GameX" || obj.name === "Pixler" || obj.name === "Builder").map((rank) =>
                    <div key={Math.random()} className="flex flex-col gap-4">
                      <div className="rankHeader flex items-center gap-3 py-2 px-4">
                        <img src={getBadgeUrl(rank.badge)} />
                        <div className="flex flex-col">
                          <p className='text-quarternary poppins-bold'>{rank.name.toUpperCase()}</p>
                          <p className='text-xs text-quarternary italic'>Rank Description bla bla lariesse mori rank</p>
                        </div>
                      </div>
                      <div className="grid grid-cols-12 gap-4 px-4">
                        {staffs.filter(obj => obj.rankId === rank.id && obj.name !== "GameX" && obj.name !== "Pixler" && obj.name !== "Xler").reverse().map((staff) =>
                          <div className="col-span-12 md:col-span-4 overflow-hidden flex items-center h-28 hover:-translate-y-1 transition-all border-[1px] sqImage border-quarternary border-opacity-50" key={staff.account.id}>
                            <div className="min-w-32 h-full flex items-center max-w-32 -ml-3">
                              <img className='!w-[128px] h-[220px] mt-12' src={getImagerLook(staff.account.look)} />
                            </div>
                            <div className="flex flex-col p-3 -ml-8 justify-center items-center w-full h-full">
                              <p><Link className='no-deco text-quarternary text-2xl' to={getProfileUrl(staff.account.username)}>{staff.account.username}</Link></p>
                              <div className="h-[1.5px] w-44 bg-black mb-2"></div>
                              <p>{staff.account.motto === null || staff.account.motto === "" ? (
                                <div className="text-xs italic text-quarternary">{LocalizeText(props.texts, 'staffs.no.motto')}</div>
                              ) : (
                                <div className="text-xs italic text-quarternary text-opacity-60" style={{ whiteSpace: "pre-line" }}>{staff.account.motto}</div>
                              )}</p>
                              <p>{rank.description}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {ranks.filter(obj => obj.name === section).map((rank) =>
                    <div className="mt-5 flex flex-col gap-2" key={Math.random()}>
                      <div className="rankHeader flex items-center gap-3 bg-primary rounded p-2">
                        <img src={getBadgeUrl(rank.badge)} />
                        <p>{rank.name.toUpperCase()}</p>
                      </div>
                      <div className="grid grid-cols-12 gap-4 px-4">
                        {staffs.filter(obj => obj.rankId === rank.id).reverse().map((staff) =>
                          <div className="col-span-12 md:col-span-6 overflow-hidden flex items-center h-24 rounded hover:-translate-y-1 transition-all bg-primary" key={staff.account.id}>
                            <div className="min-w-32 h-full flex items-center max-w-32 bg-secondary">
                              <img className='!w-[128px] h-[220px] mt-12' src={getImagerLook(staff.account.look)} />
                            </div>
                            <div className="flex flex-col p-3 w-full h-full">
                              <p><Link className='no-deco text-white text-2xl' to={getProfileUrl(staff.account.username)}>{staff.account.username}</Link></p>
                              <p>{staff.account.motto === null || staff.account.motto === "" ? (
                                <div className="text-sm italic text-white">{LocalizeText(props.texts, 'staffs.no.motto')}</div>
                              ) : (
                                <div className="text-sm italic text-white text-opacity-60" style={{ whiteSpace: "pre-line" }}>{staff.account.motto}</div>
                              )}</p>
                              <p>{rank.description}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EhrenamtPage;
