import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';

import LoadingView from '../loading/LoadingView';
import { useNavigate } from 'react-router-dom';

interface BadgeCreatorProps {
    account: any;
}


const BadgeCreatorView = (props: BadgeCreatorProps) => {
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://habbocc.hanarchy.net/e");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const [loading, setLoading] = useState(false);

    var isLoaded = true;
    useEffect(() => {
        if (isLoaded) return;
        isLoaded = true;
        document.title = "Badge Creator"
    }, [])

      
    const navigate = useNavigate();

    useEffect(() => {
        if (props.account === null) navigate("/index", { replace: true });
      }, [props]);

    return (
        <>
            {loading ? <LoadingView /> : (
                <div className="max-w-6xl mx-auto grid grid-cols-12 mt-5 animate__animated animate__fadeIn animate__slower gap-4 px-2 md:px-0">
                    <div className="col-span-12 p-1 bg-[#f9f9f9]">
                        <div className="h-32 bg-white whiteBackground flex flex-col items-center justify-center relative overflow-hidden">
                            <div className="vipImageLeft hidden md:block"></div>
                            <p className='text-4xl uppercase poppins-bold text-quarternary tracking-widest mt-2 text-center md:text-start z-10'>Badge Creator</p>
                            <div className="w-96 h-[2px] bg-black"></div>
                            <p className='text-quarternary poppins-bold text-center md:text-start z-10'>Zeige deine<span className='text-[#5aa488] mr-1 ml-1'>PIXEL</span>Fähigkeiten!</p>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-8 p-2 bg-[#f9f9f9] flex flex-col gap-3 h-max">
                        <div className="flex items-center flex-wrap justify-center gap-8">
                            <div className="btn-badge">
                                <i className="fas fa-pencil-alt item"></i>
                            </div>
                            <div className="btn-badge">
                                <i className="fas fa-eraser item"></i>
                            </div>
                            <div className="btn-badge">
                                <i className="fas fa-undo item"></i>
                            </div>
                            <div className="btn-badge">
                                <i className="fas fa-redo item"></i>
                            </div>
                            <div className="btn-badge">
                                <i className="fas fa-trash item"></i>
                            </div>
                        </div>
                        <div className="h-[400px] w-[400px] bg-black mx-auto badgeBoard shadow-xl mt-2 mb-2"></div>
                        <div className="flex items-center flex-wrap gap-x-3 mx-auto w-full gap-y-2 badgePalette">
                            <div className="item" style={{backgroundColor: "#000"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(127,127,127)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(136,0,21)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(237,28,36)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(255,127,39)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(255,242,0)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(34,177,36)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(0,162,232)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(63,72,204)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(163,73,164)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(255,255,255)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(195,195,195)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(185,122,87)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(255,174,201)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(239,228,176)"}}></div>
                            <div className="item" style={{backgroundColor: "#000"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(127,127,127)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(136,0,21)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(237,28,36)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(255,127,39)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(255,242,0)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(34,177,36)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(0,162,232)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(63,72,204)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(163,73,164)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(255,255,255)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(195,195,195)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(185,122,87)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(255,174,201)"}}></div>
                            <div className="item" style={{backgroundColor: "rgb(239,228,176)"}}></div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-4 h-max flex flex-col gap-3">
                        <div className="flex flex-col bg-[#f9f9f9]">
                            <div className="p-4 poppins-bold text-quarternary">
                                Über den Badgecreator
                            </div>
                            <hr className='m-0' />
                            <div className="flex gap-1 mb-2 flex-wrap lg:flex-nowrap">
                                <div className="p-4 py-2 text-quarternary w-full text-sm tracking-wider">
                                Wolltest du schon immer ein Badge im Hotel sehen, was du selbst gemacht hast? Dann bist du hier genau richtig!
                                </div>
                                <div className="alAvatarBox mr-1 mt-1 min-w-[200px] relative mx-auto">
                                    <div id="username">{props.account.username}</div>
                                    <div id="userFigure" className='bg-center w-16 h-[110px] mt-[3px] ml-[17px] bg-no-repeat' style={{background:`url(https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=2&size=m&action=wave&head_direction=3)`}}></div>
                                    <div className='w-[40px] h-[40px] ml-[90px] mt-[-108px]' id="badgePreview" style={{backgroundImage:"url(https://images.habbo.com/c_images/album1584/TC845.gif)"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col bg-[#f9f9f9]">
                            <div className="p-4 poppins-bold text-quarternary">
                                Badge erstellen
                            </div>
                            <hr className='m-0' />
                            <div className="flex flex-col gap-2 p-4">
                                <p className='text-quarternary'>Badge Name</p>
                                <input type="text" className='alInput !border-l-4 !border-orange-400' placeholder='Badge Name..' />

                                <p className='text-quarternary'>Badge Beschreibung</p>
                                <input type="text" className='alInput !border-l-4 !border-orange-400' placeholder='Badge Beschreibung...' />

                                <div className="alBtn alBtn-client rounded-sm text-center">Badge einreichen</div>
                                <p className='text-xs text-black text-opacity-50'>Dein Badge liegt bei der Hotelleitung zur Genehmigung vor. Bitte habe etwas Geduld</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default BadgeCreatorView;
