import React from 'react';

function Regeln() {
  return (
    <div className="max-w-6xl mx-auto grid grid-cols-12 mt-5 animate__animated animate__fadeIn animate__slower gap-4 px-2 md:px-0">
      <div className="col-span-12 p-1 bg-[#f9f9f9]">
        <div className="h-32 bg-white whiteBackground flex flex-col items-center justify-center relative overflow-hidden">
          <div className="vipImageLeft hidden md:block"></div>
          <p className='text-4xl uppercase poppins-bold text-quarternary tracking-widest mt-2 text-center md:text-start z-10'>Habbo Regelwerk</p>
          <div className="w-96 h-[2px] bg-black"></div>
        </div>
      </div>
      <div className="col-span-12 flex flex-col bg-white p-2 text-black">
        <p className='text-center'>
          Regeln
        </p>
        <p className='text-center'>
          Bitte beachte die folgenden Regeln
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §1 Verbot von Mitarbeitermanipulation und Verbreitung nachweislich falscher Informationen:
          <br />
          Es ist streng verboten, einen Mitarbeiter zu belügen oder bewusst falsche Tatsachen darzulegen.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §2 Untersagte Verhaltensweisen: Beleidigung, fortgesetzte Provokation und Hetze:
          Beleidigungen, wiederholte Provokationen oder Hetzerei gegenüber anderen Nutzern sind inakzeptabel und untersagt.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §3 Passwortbetrug:
          Das Erschleichen von Accountdaten oder der unbefugte Zugriff auf fremde Accounts durch Passwortbetrug ist streng verboten.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §4 Verbotene Äußerungen: Sexismus, Rassismus und Diskriminierung:
          Äußerungen, die sexuell, rassistisch oder diskriminierend sind und bestimmte Volksgruppen oder Religionen betreffen, sind in keiner Weise toleriert und strikt untersagt.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §5 Verbot von unangemessenen Rauminhalten (genau so wie Raumname):
          Die Erstellung von Räumen mit unangemessenem Inhalt ist nicht gestattet und wird konsequent unterbunden.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §6 Verbot von unpassenden Usernames:
          Die Verwendung eines unangemessenen Usernames ist nicht erlaubt und sollte vermieden werden.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §7 Verbot von Identitätsbetrug oder irreführender Teamzugehörigkeit:
          Das Nachahmen einer falschen Identität oder das Vortäuschen, ein Staffmitglied zu sein, ist streng untersagt und wird entsprechend geahndet.
        </p>
        <hr />
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §8 Fremdwerbung:
          Das Werben für andere Hotels oder das Teilen von Links zu unangemessenen Inhalten ist nicht gestattet und wird als Fremdwerbung betrachtet.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §9 Multiaccount:
          Die Nutzung von maximal zwei Accounts ist erlaubt, jedoch ist die Regel "Pro IP 2 User" nicht gültig.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §10 Benutzen von Cheating-Tools:
          Die Verwendung von Programmen, die einen unfairen Vorteil im Spiel verschaffen, ist strikt untersagt.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §11 Verbot der Veröffentlichung und Verbreitung privater Daten:
          Die Veröffentlichung oder Verbreitung von privaten Kontaktdaten, Bildern oder anderen persönlichen Informationen ist streng verboten und wird mit Konsequenzen geahndet.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §12 Verbotener Austausch von Echtgeld zwischen Usern:
          Der Handel mit digitalen Gütern gegen Echtgeld zwischen den Usern ist nicht erlaubt und wird als Verstoß gegen die Regeln angesehen.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §13 Geldwäsche:
          Es ist nicht erlaubt, Taler, Diamanten, Duckets oder Rares durch das Erstellen mehrerer Accounts oder ähnliche Methoden zu vermehren.
        </p>
        <p className='text-slate-900 text-sm mt-2 mb-2'>
          §14 Illegales Glücksspiel:
          Das Durchführen von unerlaubtem Glücksspiel ist untersagt.
        </p>
        <hr />
      </div>
    </div>
  );
}

export default Regeln;
