import React, { useEffect, useRef, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import "./Dashboard.scss";
import LoadingView from '../loading/LoadingView';

import { timeSince, getPreviewImage, getImagerForTops, getNewsBackgroundCss, getNewsBackgroundHref, getArticleRoute, getProfileUrl, LocalizeText } from '../../Globals';

import { NewsComposer } from '../../packets/NewsComposer';
import { TweetsComposer } from '../../packets/TweetsComposer';
import { CreateTweetComposer } from '../../packets/CreateTweetComposer';
import { DeleteTweetComposer } from '../../packets/DeleteTweetComposer';
import { TopsComposer } from '../../packets/TopsComposer';
import { NotificationManager } from 'react-notifications';

const DashboardView = (props) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://habbocc.hanarchy.net/e");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

  const [effect, setEffect] = useState(30);
  const [background, setBackground] = useState("https://files.habboemotion.com/resources/images/website_backgrounds/bg_blue.gif");
  const [news, setNews] = useState<any>(null);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);


  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();


  useEffect(() => {
    if (props.account === null) navigate("/index", { replace: true });
  }, [props]);

  var isLoaded = false;
  useEffect(() => {
    if (isLoaded) return;
    isLoaded = true;
    document.title = "Dashboard | " + "Habbo"

    sendMessage(JSON.stringify(new NewsComposer()))
    sendMessage(JSON.stringify(new TweetsComposer()))
    sendMessage(JSON.stringify(new TopsComposer()))

    if (props.account === null) navigate("/index", { replace: true });
    if (window.localStorage.getItem("kubboch-sto") === null || window.sessionStorage.getItem("kubboch-stoc") === null) navigate("/index", { replace: true });
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNewsIndex(prevIndex => (prevIndex + 1) % news.length);
    }, 10000); // 15 saniyede bir geçiş yapacak
  
    return () => clearInterval(interval); // Bileşen kaldırıldığında interval'i temizleyin
  }, [news]);


  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);

      if (message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));

    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if (news !== null) setLoading(false)
  }, [news])

  const logout = () => {
    props.logout();
    navigate("/index", { replace: true });
}

const location = useLocation();

const [clientInit, setclientInit] = useState(false);
const [visibility, setVisibility] = useState<DocumentVisibilityState>("visible")


useEffect(() => {
    if (location.pathname === "/play") {
        setclientInit(true);
        setVisibility("visible");
    }
    else setVisibility("hidden");

}, [location]);

const getSrcIframe = (sso) => "https://nitro.habbo.cc/index.html?sso=" + sso;


  return (
    <>
      {props.account != null &&
        <>
          {loading ? <LoadingView /> : (
            <>
              <div className='max-w-6xl mx-auto grid grid-cols-12 px-2 gap-y-4 animate__animated animate__fadeIn animate__slower mt-5 mb-8'>
                <div className="col-span-12 grid grid-cols-12">
                  <div className="col-span-12 md:col-span-9 flex flex-col">
                    <div className="h-52 indexPhoto relative overflow-hidden flex gap-3 lg:gap-0 flex-col lg:flex-row justify-center items-center lg:justify-start lg:items-end">
                      <div className="absolute right-0 top-0 px-3 py-2 bg-[#ededed] flex items-center gap-1 text-xs text-quarternary z-10">
                        <i className="fa-solid fa-users"></i>
                        <b>{props.online} Mitglieder online</b>
                      </div>
                      <div className="absolute w-full h-full bg-black bg-opacity-70 block lg:hidden"></div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 300" className='absolute hidden lg:block -bottom-12 -ml-28 yansitilmis-div'>
                        <path fill="#edefef" fill-opacity="1" d="M0,224L60,197.3C120,171,240,117,360,90.7C480,64,600,64,720,80C840,96,960,128,1080,144C1200,160,1320,160,1380,160L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
                      </svg>
                      <div className="w-32 h-32 absolute left-0 hidden lg:flex items-center justify-center rounded-full">
                        <img className='-mt-4' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=2&size=l&action=wave&head_direction=3`} />
                      </div>
                      <div className="flex flex-col relative lg:ml-32 lg:-top-5 gap-1">
                        <p className='text-lg lg:text-quarternary font-[500]'>Hey <b>{props.account.username}</b>, alles fit?</p>
                        <p className='text-sm italic lg:text-quarternary text-center md:text-start'>Wilkommen im Habbo!</p>
                      </div>
                      <div className="lg:absolute lg:right-3 lg:bottom-0.5 relative flex gap-2 items-center">
                        <a onClick={() => logout()} className="py-2 px-4 flex items-center cursor-pointer gap-2 justify-center bg-red-600 border-b-2 border-red-700 rounded-md">
                          <i className="fa-solid fa-arrow-right-from-bracket"></i>
                          Ausloggen
                        </a>
                        <Link to={"/play"} className="py-2 px-4 flex items-center gap-2 justify-center bg-green-600 border-b-2 border-green-700 rounded-md">
                          <i className="fa-solid fa-gamepad"></i>
                          Einchecken
                        </Link>
                      </div>

                    </div>
                    <div className="progress w-full h-1 bg-orange-100 bg-opacity-100">
                      <div className="w-1/3 h-full bg-orange-400 bg-opacity-80"></div>
                    </div>
                    <div className="py-3 px-2 bg-[#fcfcfc] border-b-4 border-b-gray-300 flex items-center md:items-start flex-col md:flex-row gap-2">
                      <div className="flex items-center gap-1 px-2.5 text-sm text-orange-400 md:border-r-[1px] border-gray-200">
                        <i className="fa-solid fa-turn-up"></i>
                        HabboCC
                      </div>
                      <div className="flex items-center gap-1 px-1.5 poppins-medium text-sm text-black md:border-r-[1px] border-gray-200">
                        <img src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhP0QygKtudLCVGXpOyFqq3jqw_N7B1SjwMZJr0kqCyz8SwN5amnCgBtLlDjmnYDknpEoYw6bnkYoAsWLy3Y36MhyC8H61GCWG_hWtGFz-CQBdRGsw_HO2ZatEmub8_J673U2ZghkQtt9v3/s1600/gcreate_icon_credit.png" alt="" />
                        {props.account.credits} Taler
                      </div>
                      <div className="flex items-center gap-1 px-1.5 poppins-medium text-sm text-black md:border-r-[1px] border-gray-200 opacity-60">
                        <img src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg24zHkKjL7GvKLCtvySPuORWSy8_Sp_qdDR4waNopMzVklnICSeZKIFKSx8lJyKFK9im5b5PxIAFP8kwyow-q-EWzOk_vbtx1j__REM_Ar6Q3VY5Rn_FwfpzmBxSTN7WNZ4tBcieKpOWZK/s0/Diamante.png" alt="" />
                        {props.account.diamonds} Diamanten
                      </div>
                      <div className="flex items-center gap-1 px-1.5 poppins-medium text-sm text-black md:border-r-[1px] border-gray-200 opacity-50">
                        <img src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjXHIdTKwRzeXe8nP9d0Scqr9-QOX8lX-fWGyuklearms-eSHKUjCmS5cUtIihRLCPPKAryfqiqaP4fhHtMbzGu1oASTFvo94P5VqLemC10vfiJSxr6hT5yJbjeADel9E73KcaoBRq8rl5Q/s0/705_HabboWindowManagerCom_toolbar_duckat_icon_0.png" alt="" />
                        {props.account.currency} Duckets
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3 grid grid-cols-3 bg-[#ededed] bg-no-repeat !bg-center border-l-2 border-gray-200 border-b-gray-300 border-b-4" style={{ backgroundImage: "url(https://habbo.cc/static/media/bg-furni.519e1d50dc64d28ed819.png)" }}>
                    <div className="col-span-1 w-full p-2 triangleOnlineBoxes h-20 flex items-center justify-center overflow-hidden">
                      <img className='mt-4 absolute' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=m&head_direction=3`} />
                    </div>
                    <div className="col-span-1 w-full p-2 triangleOnlineBoxes h-20 flex items-center justify-center overflow-hidden">
                      <img className='mt-4 absolute' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=m&head_direction=3`} />
                    </div>
                    <div className=":col-span-1 w-full p-2 triangleOnlineBoxes h-20 flex items-center justify-center overflow-hidden">
                      <img className='mt-4 absolute' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=m&head_direction=3`} />
                    </div>
                    <div className="col-span-1 w-full p-2 triangleOnlineBoxes h-20 flex items-center justify-center overflow-hidden">
                      <img className='mt-4 absolute' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=m&head_direction=3`} />
                    </div>
                    <div className=":col-span-1 w-full p-2 triangleOnlineBoxes h-20 flex items-center justify-center overflow-hidden">
                      <img className='mt-4 absolute' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=m&head_direction=3`} />
                    </div>
                    <div className="col-span-1 w-full p-2 triangleOnlineBoxes h-20 flex items-center justify-center overflow-hidden">
                      <img className='mt-4 absolute' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=m&head_direction=3`} />
                    </div>
                    <div className=":col-span-1 w-full p-2 triangleOnlineBoxes h-20 flex items-center justify-center overflow-hidden">
                      <img className='mt-4 absolute' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=m&head_direction=3`} />
                    </div>
                    <div className="col-span-1 w-full p-2 triangleOnlineBoxes h-20 flex items-center justify-center overflow-hidden">
                      <img className='mt-4 absolute' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=m&head_direction=3`} />
                    </div>
                    <div className="col-span-1 w-full p-2 triangleOnlineBoxes h-20 flex items-center justify-center overflow-hidden">
                      <img className='mt-4 absolute' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=m&head_direction=3`} />
                    </div>
                  </div>
                </div>
                {news != null &&
                  <div className="col-span-12 grid grid-cols-12">
                    {news && news.length > 0 && (
                    <div className="col-span-12 md:col-span-8 w-full h-[300px] border-b-4 border-b-gray-300 relative !bg-center" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[currentNewsIndex].header)})` }}>
                      <div className="absolute bottom-0 bg-[#fafafa] bg-opacity-90 w-full flex justify-between">
                        <div className="p-2 flex items-center gap-2">
                          <div className="w-12 h-12 p-2 !bg-center !bg-cover" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[currentNewsIndex].header)})` }}></div>
                          <div className="flex flex-col">
                            <p className='text-quarternary poppins-bold'>{news[currentNewsIndex].title}</p>
                            <p className='text-xs text-quarternary'>... {news[currentNewsIndex].shortStory}</p>
                          </div>
                        </div>
                        <Link to={getArticleRoute(news[currentNewsIndex].id,news[currentNewsIndex].title)} className="w-max px-3 h-full absolute right-0 bg-[#fafafa] flex items-center justify-center text-quarternary text-sm poppins-medium border-l-2 border-gray-200 text-opacity-70">
                          Artikel Lesen
                        </Link>
                      </div>
                    </div>
                    )}
                    <div className="col-span-12 md:col-span-4 flex flex-col h-[300px] border-b-4 border-b-gray-300 justify-between">
                      {news[1] != null &&
                      <Link className="h-full px-4 flex bg-[#ededed] items-center justify-center w-full gap-2" to={getArticleRoute(news[1].id, news[1].title)}>
                        <div className="w-12 h-12 min-w-12 min-h-12 p-2 !bg-center !bg-cover" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[1].header)})` }}></div>
                        <div className="flex flex-col w-full truncate">
                          <p className='text-quarternary poppins-bold truncate'>{news[1].title}</p>
                          <p className='text-xs text-quarternary truncate'>... {news[1].shortStory}</p>
                        </div>
                      </Link>
                      }
                      {news[2] != null &&
                      <Link className="h-full px-4 flex bg-[#ededed] items-center justify-center w-full gap-2" to={getArticleRoute(news[2].id, news[2].title)}>
                      <div className="w-12 h-12 min-w-12 min-h-12 p-2 !bg-center !bg-cover" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[2].header)})` }}></div>
                      <div className="flex flex-col w-full truncate">
                        <p className='text-quarternary poppins-bold truncate'>{news[2].title}</p>
                        <p className='text-xs text-quarternary truncate'>... {news[2].shortStory}</p>
                      </div>
                      </Link>
                      }
                      {news[3] != null &&
                      <Link className="h-full px-4 flex bg-[#ededed] items-center justify-center w-full gap-2" to={getArticleRoute(news[3].id, news[3].title)}>
                      <div className="w-12 h-12 min-w-12 min-h-12 p-2 !bg-center !bg-cover" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[3].header)})` }}></div>
                      <div className="flex flex-col w-full truncate">
                        <p className='text-quarternary poppins-bold truncate'>{news[3].title}</p>
                        <p className='text-xs text-quarternary truncate'>... {news[3].shortStory}</p>
                      </div>
                      </Link>
                      }
                      {news[4] != null &&
                      <Link className="h-full px-4 flex bg-[#ededed] items-center justify-center w-full gap-2" to={getArticleRoute(news[4].id, news[4].title)}>
                      <div className="w-12 h-12 min-w-12 min-h-12 p-2 !bg-center !bg-cover" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[4].header)})` }}></div>
                      <div className="flex flex-col w-full truncate">
                        <p className='text-quarternary poppins-bold truncate'>{news[4].title}</p>
                        <p className='text-xs text-quarternary truncate'>... {news[4].shortStory}</p>
                      </div>
                      </Link>
                      }
                    </div>
                  </div>
                }
                <div className="col-span-12 md:col-span-6 md:mr-3 gap-y-4">
                  <iframe src="https://discord.com/widget?id=1092565520114516059&theme=dark" className='w-full h-96' allowTransparency={true} sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                </div>
                <div className="col-span-12 md:col-span-6 bg-[#fcfcfc] md:ml-3 shadow-lg rounded-sm">
                  <div className="mt-2 py-2  px-2 md:px-4 w-full flex gap-3 items-center">
                    <div className="expertIcon"></div>
                    <div className="flex flex-col">
                      <p className='text-quarternary poppins-bold text-lg'>eXperten Bewerbungsrunden</p>
                      <p className='text-xs text-quarternary '>Deine Chance - hier und jetzt!</p>
                    </div>
                  </div>
                  <hr className='mt-2 mb-4' />
                  <p className='px-3 text-quarternary text-sm poppins-medium text-opacity-70'>Event wurde bereits veranstaltet.</p>
                  <hr className='mt-4 mb-4' />
                </div>
              </div>
            </>
          )}
        </>
      }
    </>
  )
}

export default DashboardView
