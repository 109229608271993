import React, { useState } from 'react'
import { timeSince } from '../../Globals';
import { Link } from 'react-router-dom';

const VipUserItemView = (props) => {
    const [onMouse, setOnMouse] = useState(false);

    return (
        <Link to={"/profile/" + props.username} className="col-span-12 md:col-span-4 flex items-center w-max overflow-hidden h-28 hover:-translate-y-1 transition-all border-[1px] sqImage border-quarternary border-opacity-50" >
            <div className="min-w-32 h-full flex items-center max-w-32">
                <img className='!w-[128px] h-[220px] mt-12'  src={`https://nitro-imager.habbo.gt/?figure=${props.look}&direction=3&size=l&head_direction=3${onMouse ? "&action=wlk,wav,drk=1" : ""}`}  />
            </div>
            <div className="flex flex-col p-3 -ml-8 justify-center items-center w-full h-full">
                <p className='text-2xl text-quarternary'>{props.username}</p>
                <div className="h-[1.5px] w-44 bg-black mb-2"></div>
                <p className='text-xs text-opacity-70 text-quarternary italic'>Danke für deine Unterstützung!</p>
            </div>
        </Link>
    )
}

export default VipUserItemView
