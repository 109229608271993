import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { getArticleRoute, LocalizeText, timeSince } from '../../Globals'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { NotificationManager } from 'react-notifications';
import { LoginComposer } from '../../packets/LoginComposer';

import "./Welcome.scss";
interface WelcomeProps {
    texts: { [key: string]: string };
    account: any;
}

const WelcomeView = (props: WelcomeProps) => {
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://habbocc.hanarchy.net/e");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const closeRef =  useRef<HTMLButtonElement>(null);
  
    const [username, setUsername] =  useState("");
    const [password, setPassword] = useState("");
  
    const navigate = useNavigate();

    useEffect(() => {
        if(props.account !== null) navigate("/me", { replace: true });
      }, [props]);
  
    const login = () => {
  
      if(username == "" || password == ""){
          NotificationManager.error(LocalizeText(props.texts, "login.error.spaces"), "Error");
          return;
      }
      
      sendMessage(JSON.stringify(new LoginComposer(username, password)))
    }
  
    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);
            if(message.header === "loginResponse" && message.data != null){
                if(message.data.data === "Contraseña incorrecta!") NotificationManager.error(LocalizeText(props.texts, "login.error.wrongpassword"), "Error");
                else if(message.data.data === "Este usuario no existe!") NotificationManager.error(LocalizeText(props.texts, "login.error.usernotfound"), "Error");
                else if(message.data.data === "Esta cuenta esta baneada") NotificationManager.error(LocalizeText(props.texts, "login.error.banned"), "Error");
                else{
                    closeRef.current?.click()
                    NotificationManager.success(LocalizeText(props.texts, "login.success.session"), "OK");
                    window.localStorage.setItem("kubboch-sto", message.data.data);
                    navigate("/me", { replace: true });
                }
            }
        }
      }, [lastMessage, setMessageHistory]);

    return (
        <>
                <div className='h-screen w-full flex items-center justify-center'>

                    <div className="grid grid-cols-12 max-w-7xl w-full gap-3 p-2 mt-2">
                        <div className="col-span-12 p-2 h-auto md:h-12 relative bg-quarternary flex items-center">
                            <div className="indexFrankImage hidden md:block absolute bottom-0"></div>
                            <div className='md:px-20 flex flex-wrap md:flex-nowrap text-center md:text-start items-center gap-2'>
                                <p className='text-center md:text-start w-full md:w-auto relative top-2 md:top-0'>***WILKOMMEN***</p>
                                <p className='text-white text-opacity-80 font-extralight'>Hallo lieber Besucher, registriere dich heute noch im Habbo Hotel und erlebe unsere Welt!</p>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-8 hidden md:flex flex-col drop-shadow-2xl">
                            <div className="h-full indexPhoto relative flex justify-center items-center">
                                <div className="absolute w-full h-full bg-black bg-opacity-50"></div>
                                    <div className="HabboLogo flex justify-center items-center relative">
                                        <div className="absolute -bottom-12 px-5 py-2 text-sm bg-quarternary rounded-lg flex items-center gap-1 bg-opacity-80">23 Spieler Online!</div>
                                    </div>
                                    <div className="absolute bottom-0 p-3 w-full bg-quarternary bg-opacity-70 flex flex-col">
                                    <p className="text-2xl">Wilkommen im HabboCC</p>
                                    <p className="text-sm text-white text-opacity-70">Habbo ist eine virtuelle Welt für Kinder und Jugendliche. Hier bei uns aknnst du eigene Raume erstellen!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-4 p-2 bg-quarternary flex flex-col drop-shadow-2xl">
                            <div className="HabboLogo mx-auto block md:hidden mt-2"></div>
                            <p className='text-xl text-center mt-1 mb-2'>Einchecken im Hotel</p>
                            <div className="flex flex-col gap-4 px-5 mt-2">
                                <div className="flex flex-col gap-1">
                                    <p>Username</p>
                                    <input  onChange={(e) => setUsername(e.target.value)} className='newInput !border-l-4 !border-orange-400' type="text" placeholder='Username' />
                                </div>
                                <div className="flex flex-col gap-1 mb-3">
                                    <p>Password</p>
                                    <input onChange={(e) => setPassword(e.target.value)} className='newInput !border-l-4 !border-orange-400' type="password" placeholder='Username' />
                                </div>
                                <button onClick={() => login()} className='alBtn alBtn-client rounded-sm'>Einloggen</button>
                                <span className='text-center -mb-2 -mt-2 text-sm text-white text-opacity-70'>oder</span>
                                <Link className='alBtn alBtn-danger rounded-sm mb-2 text-center' to={'/register'}>Registrieren</Link>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-4 bg-quarternary hidden md:flex items-center gap-3">
                            <div className="max-w-[95px] min-w-[95px] max-h-[92px] min-h-[92px]"style={{background:"url(https://web.archive.org/web/20180304005246im_/http://live-hotel.biz/images/badgeomatic.png)"}}></div>
                            <p className="text-sm">Erstelle einzigartige Badges mit unserem Badge-o-matic!</p>
                        </div>
                        <div className="col-span-12 md:col-span-4 bg-quarternary hidden md:flex items-center gap-3">
                            <div className="max-w-[95px] min-w-[95px] max-h-[92px] min-h-[92px] !bg-center"style={{background:"url(https://web.archive.org/web/20180304005017im_/http://live-hotel.biz/images/wireds.png)"}}></div>
                            <p className="text-sm">Baue spannende Events mit unseren Wireds, die alle zu 100% funktionieren!</p>
                        </div>
                        <div className="col-span-12 md:col-span-4 bg-quarternary hidden md:flex items-center gap-3">
                            <div className="max-w-[95px] min-w-[95px] max-h-[92px] min-h-[92px]"style={{background:"url(https://web.archive.org/web/20180304005209im_/http://live-hotel.biz/images/stapelfelder.png)"}}></div>
                            <p className="text-sm">Kreiere atemberaubende Räume mit unseren Stapelfeldern!</p>
                        </div>
                        <div className="col-span-12 flex flex-col items-center justify-center gap-1 relative top-4">
                            <p className='text-white text-opacity-70 text-sm'>Copyright © 2025 HabboCC Hotel</p>
                            <div className="flex items-center gap-2 text-white text-opacity-70 text-[11px]">
                                <p>Impressum</p>
                                <p>Regelwerk</p>
                                <p>Kontaktiere Uns</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
        </>
    )
}

export default WelcomeView
