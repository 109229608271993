import React, { useEffect, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { LocalizeText } from '../../Globals';

import LoadingView from '../loading/LoadingView';
import { SettingsComposer } from '../../packets/SettingsComposer';
import { UpdateSettingsComposer } from '../../packets/UpdateSettingsComposer';
import { UpdatePasswordComposer } from '../../packets/UpdatePasswordComposer';

const SettingsView = (props) => {
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://habbocc.hanarchy.net/e");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const navigate = useNavigate();

    const [motto, setMotto] = useState("");
    const [email, setEmail] = useState("");
    const [follow, setFollow] = useState(0);
    const [friends, setFriends] = useState(0);
    const [rooms, setRooms] = useState(0);
    const [alerts, setAlerts] = useState(0);
    const [trades, setTrades] = useState(0);

    const [actualPassword, setActualPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "settingsInfo" && message.data !== null) {
                setMotto(message.data.motto);
                setEmail(message.data.email);
                setFollow(message.data.follow);
                setFriends(message.data.friends);
                setRooms(message.data.rooms);
                setAlerts(message.data.alerts);
                setTrades(message.data.trades);

                setLoading(false);
            }

            if (message.header === "SettingResponse" && message.data !== null) {
                if (message.data.data === "Este correo ya existe") NotificationManager.error(LocalizeText(props.texts, 'settings.error.email'), "Error");
                else if (message.data.data === "Algo salio mal") NotificationManager.error(LocalizeText(props.texts, 'settings.error'), "Error");
                else NotificationManager.success(LocalizeText(props.texts, 'settings.success'), "OK");
            }

            if (message.header === "passwordResponse" && message.data !== null) {
                if (message.data.data === "Contraseña actual incorrecta") NotificationManager.error(LocalizeText(props.texts, 'settings.error.password.actual'), "Error");
                else if (message.data.data === "La contraseña debe ser mayor a 6 digitos") NotificationManager.error(LocalizeText(props.texts, 'settings.error.password.length'), "Error");
                else if (message.data.data === "La nueva contraseña no coincide con la confirmacion") NotificationManager.error(LocalizeText(props.texts, 'settings.error.password.error'), "Error");
                else NotificationManager.success(LocalizeText(props.texts, 'settings.success'), "OK");
            }
        }
    }, [lastMessage, setMessageHistory]);


    useEffect(() => {
        document.title = LocalizeText(props.texts, 'settings.title') + " | " + "Habbo"
        if (props.account === null) navigate("/index", { replace: true });
        sendMessage(JSON.stringify(new SettingsComposer()));
    }, [])

    const updateSettings = () => {
        if (motto === "" || email === "") {
            NotificationManager.error(LocalizeText(props.texts, 'settings.input.empty'), "Error");
            return;
        }

        sendMessage(JSON.stringify(new UpdateSettingsComposer(motto, email, follow, friends, rooms, alerts, trades)));
    }

    const updatePassword = () => {
        if (newPassword !== confirmPassword) {
            NotificationManager.error(LocalizeText(props.texts, 'settings.password.invalid'), "Error");
            return;
        }

        sendMessage(JSON.stringify(new UpdatePasswordComposer(actualPassword, newPassword, confirmPassword)));
    }

    return (
        <>
            {loading ? <LoadingView /> : (
                <div className='max-w-7xl mx-auto w-full gap-4 grid grid-cols-12 container-login animate__animated animate__fadeIn animate__slower mt-5'>
                    <div className='col-span-12 md:col-span-6'>
                        <div className="flex flex-col  bg-[#fcfcfc] ">
                            <div className="flex items-center gap-3 p-4 poppins-bold text-quarternary text-xl">
                                <p>{LocalizeText(props.texts, 'settings.head.title')}</p>
                            </div>
                            <hr className='m-0' />
                            <div className="flex flex-col mt-2 rounded px-4 py-2 gap-3">
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.mision')}</b>
                                    <input className='alInput !w-1/2  !border-4 !border-orange-400' type="text" value={motto} onChange={(e) => setMotto(e.target.value)} />
                                </div>
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.email')}</b>
                                    <input className='alInput !w-1/2  !border-l-4 !border-orange-400' type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.bfollow.title')}</b>
                                    <select className='alInput !w-1/2  !border-l-4 !border-orange-400' value={follow} onChange={(e) => setFollow(parseInt(e.target.value))}>
                                        <option value="0">{LocalizeText(props.texts, 'settings.bfollow.no')}</option>
                                        <option value="1">{LocalizeText(props.texts, 'settings.bfollow.yes')}</option>
                                    </select>
                                </div>
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.rfriends.title')}</b>
                                    <select className='alInput !w-1/2  !border-l-4 !border-orange-400' value={friends} onChange={(e) => setFriends(parseInt(e.target.value))}>
                                        <option value="0">{LocalizeText(props.texts, 'settings.rfriends.no')}</option>
                                        <option value="1">{LocalizeText(props.texts, 'settings.rfriends.yes')}</option>
                                    </select>
                                </div>
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.invite.title')}</b>
                                    <select className='alInput !w-1/2  !border-l-4 !border-orange-400' value={rooms} onChange={(e) => setRooms(parseInt(e.target.value))}>
                                        <option value="0">Einladungen zulassen</option>
                                        <option value="1">{LocalizeText(props.texts, 'settings.invite.no')}</option>
                                    </select>
                                </div>
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.alerts.title')}</b>
                                    <select className='alInput !w-1/2  !border-l-4 !border-orange-400' value={alerts} onChange={(e) => setAlerts(parseInt(e.target.value))}>
                                        <option value="0">{LocalizeText(props.texts, 'settings.alerts.no')}</option>
                                        <option value="1">{LocalizeText(props.texts, 'settings.alerts.yes')}</option>
                                    </select>
                                </div>
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.trade.title')}</b>
                                    <select className='alInput !w-1/2  !border-l-4 !border-orange-400' value={trades} onChange={(e) => setTrades(parseInt(e.target.value))}>
                                        <option value="1">{LocalizeText(props.texts, 'settings.trade.no')}</option>
                                        <option value="0">{LocalizeText(props.texts, 'settings.trade.yes')}</option>
                                    </select>
                                </div>

                                <div className=''>
                                    <button onClick={() => updateSettings()} className='jeHeaderLoginButton rounded w-full p-2 mb-2'>{LocalizeText(props.texts, 'settings.data.button')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 md:col-span-6 '>
                        <div className="flex flex-col bg-[#fcfcfc]">
                            <div className="flex items-center gap-3 rounded p-4 poppins-bold  text-quarternary text-xl">
                                <p>{LocalizeText(props.texts, 'settings.head2.title')}</p>
                            </div>
                            <hr className='m-0' />
                            <div className="flex flex-col mt-2 rounded px-4 py-2">
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.password.actual')}</b>
                                    <input onChange={(e) => setActualPassword(e.target.value)} className='alInput !w-1/2  !border-l-4 !border-orange-400' type="password" />
                                </div>
                            </div>
                            <div className="flex flex-col mt-2 rounded px-4 py-2">
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.password.new')}</b>
                                    <input onChange={(e) => setNewPassword(e.target.value)} className='alInput !w-1/2  !border-l-4 !border-orange-400' type="password" />
                                </div>
                            </div>
                            <div className="flex flex-col mt-2 rounded px-4 py-2">
                                <div className="flex items-center justify-between text-quarternary">
                                    <b className='w-1/2'>{LocalizeText(props.texts, 'settings.password.confirm')}</b>
                                    <input onChange={(e) => setConfirmPassword(e.target.value)} className='alInput !w-1/2  !border-l-4 !border-orange-400' type="password" />
                                </div>
                            </div>
                            <div className='mb-2 px-4 py-2 rounded mt-3'>
                                <button onClick={() => updatePassword()} className='jeHeaderLoginButton rounded w-full p-2 '>{LocalizeText(props.texts, 'settings.password.button')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SettingsView
