import React, { useEffect } from 'react'
import "./Loading.scss";

const LoadingView = () => {
  useEffect(() => {

  }, [])

  return (
    <div className="loader" id="loadingScreen">
      <div className="spin flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    </div>
  )
}

export default LoadingView
