import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';

import LoadingView from '../loading/LoadingView';
import { LocalizeText } from '../../Globals';
import { VipsComposer } from '../../packets/VipsComposer';

import './Vips.scss'
import VipUserItemView from './VipUserItemView';

const VipsView = (props) => {
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://habbocc.hanarchy.net/e");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const [loading, setLoading] = useState(true);
    const [vips, setVips] = useState<any[]>([]);

    var isLoaded = false;
    useEffect(() => {
        if (isLoaded) return;
        isLoaded = true;

        document.title = "VIP HabboCC"
        sendMessage(JSON.stringify(new VipsComposer()))
    }, [])

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "usersVips" && message.data !== null) {
                setVips(message.data.vips)
                setLoading(false);
            }
        }
    }, [lastMessage, setMessageHistory]);

    const getRandomVips = () => {
        const shuffledVips = vips.sort(() => 30 - Math.random());
        return shuffledVips.slice(0, 6);
    };

    const randomVips = getRandomVips();

    return (
        <>
            {loading ? <LoadingView /> : (
                <div className="max-w-6xl mx-auto grid grid-cols-12 mt-5 animate__animated animate__fadeIn animate__slower gap-4 px-2 md:px-0">
                    <div className="col-span-12 p-1 bg-[#f9f9f9]">
                        <div className="h-32 bg-white whiteBackground flex flex-col items-center justify-center relative overflow-hidden">
                            <div className="vipImageLeft hidden md:block"></div>
                            <p className='text-4xl uppercase poppins-bold text-quarternary tracking-widest mt-2 text-center md:text-start z-10'>Habbo PREMIUM</p>
                            <div className="w-96 h-[2px] bg-black"></div>
                            <p className='text-quarternary poppins-bold text-center md:text-start z-10'>Bist du bereit<span className='text-[#5aa488] mr-1 ml-1'>PREMIUM</span>zu werden?</p>
                        </div>
                    </div>

                    <div className="col-span-12 grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-4 p-1 bg-[#f9f9f9] flex flex-col">
                            <div className="h-24 !bg-center flex flex-col items-center justify-center overflow-hidden relative">
                                <div className="absolute w-full h-full whiteBackground"></div>
                                <div className="vipPacketBackground absolute hidden md:block opacity-10"></div>
                                <p className='text-xl uppercase relative text-quarternary poppins-bold'>Bronze Premium</p>
                                <div className="w-20 h-[2px] bg-black relative"></div>
                                <p className='text-quarternary relative text-lg poppins-semibold'>4,99 € Monatlich</p>
                            </div>
                            <div className="flex flex-col mt-2 items-center justify-center">
                                <div className="text-quarternary tracking-widest poppins-bold">This package including</div>
                                <div className="flex flex-col gap-1 mt-1 text-quarternary text-sm">
                                    <p>&#x2022; Special Badge</p>
                                    <p>&#x2022; 7500 Taler</p>
                                    <p>&#x2022; Lenny's Smile</p>
                                    <p>&#x2022; Seldom's Smile</p>
                                    <p>&#x2022; Special Badge</p>
                                    <p>&#x2022; 7500 Taler</p>
                                    <p>&#x2022; Lenny's Smile</p>
                                    <p>&#x2022; Seldom's Smile</p>
                                </div>
                                <button className="w-2/3 bg-blue-400 p-2 mt-2 text-center rounded-sm">
                                    Premium Kaufen
                                </button>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-4 p-1 bg-[#f9f9f9] flex flex-col">
                            <div className="h-24 !bg-center flex flex-col items-center justify-center overflow-hidden relative">
                                <div className="absolute w-full h-full whiteBackground"></div>
                                <div className="vipPacketBackground absolute hidden md:block opacity-10"></div>
                                <p className='text-xl uppercase relative text-quarternary poppins-bold'>Bronze Premium</p>
                                <div className="w-20 h-[2px] bg-black relative"></div>
                                <p className='text-quarternary relative text-lg poppins-semibold'>4,99 € Monatlich</p>
                            </div>
                            <div className="flex flex-col mt-2 items-center justify-center">
                                <div className="text-quarternary tracking-widest poppins-bold">This package including</div>
                                <div className="flex flex-col gap-1 mt-1 text-quarternary text-sm">
                                    <p>&#x2022; Special Badge</p>
                                    <p>&#x2022; 7500 Taler</p>
                                    <p>&#x2022; Lenny's Smile</p>
                                    <p>&#x2022; Seldom's Smile</p>
                                    <p>&#x2022; Special Badge</p>
                                    <p>&#x2022; 7500 Taler</p>
                                    <p>&#x2022; Lenny's Smile</p>
                                    <p>&#x2022; Seldom's Smile</p>
                                </div>
                                <button className="w-2/3 bg-blue-400 p-2 mt-2 text-center rounded-sm">
                                    Premium Kaufen
                                </button>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-4 p-1 bg-[#f9f9f9] flex flex-col">
                            <div className="h-24 !bg-center flex flex-col items-center justify-center overflow-hidden relative">
                                <div className="absolute w-full h-full whiteBackground"></div>
                                <div className="vipPacketBackground absolute hidden md:block opacity-10"></div>
                                <p className='text-xl uppercase relative text-quarternary poppins-bold'>Bronze Premium</p>
                                <div className="w-20 h-[2px] bg-black relative"></div>
                                <p className='text-quarternary relative text-lg poppins-semibold'>4,99 € Monatlich</p>
                            </div>
                            <div className="flex flex-col mt-2 items-center justify-center">
                                <div className="text-quarternary tracking-widest poppins-bold">This package including</div>
                                <div className="flex flex-col gap-1 mt-1 text-quarternary text-sm">
                                    <p>&#x2022; Special Badge</p>
                                    <p>&#x2022; 7500 Taler</p>
                                    <p>&#x2022; Lenny's Smile</p>
                                    <p>&#x2022; Seldom's Smile</p>
                                    <p>&#x2022; Special Badge</p>
                                    <p>&#x2022; 7500 Taler</p>
                                    <p>&#x2022; Lenny's Smile</p>
                                    <p>&#x2022; Seldom's Smile</p>
                                </div>
                                <button className="w-2/3 bg-blue-400 p-2 mt-2 text-center rounded-sm">
                                    Premium Kaufen
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='col-span-12 flex flex-col gap-2 bg-[#fcfcfc]'>
                        <div className="flex items-center p-3 text-lg text-quarternary poppins-bold">
                            <div className="vipImage"></div>
                            Zufällige VIPs
                        </div>
                        <hr className='m-0 -mt-4 mb-2' />
                        <div className="grid grid-cols-12 gap-4 px-3 mb-3">
                            {randomVips.map((x, index) => (
                                <VipUserItemView key={index} look={x.look} username={x.username} />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default VipsView;
