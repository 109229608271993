import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const NavbarSecondaryView = (props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);


  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  return (
    <nav className="bg-primary border-gray-200">
      <div className="max-w-6xl w-full flex flex-wrap items-center justify-between mx-auto py-4">
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-secondary focus:outline-none focus:ring-2 focus:bg-secondary"
          aria-controls="navbar-default"
          aria-expanded={isMenuOpen ? "true" : "false"}>
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <div className={`${isMenuOpen ? 'block' : 'hidden'
          } w-full lg:block lg:w-auto mb-2`} id="navbar-default">
          <ul className="font-medium flex flex-col p-4 md:p-0 md:py-1 mt-4 border border-gray-100 items-center lg:flex-row md:space-x-3 rtl:space-x-reverse md:mt-0 md:border-0 bg-primary">
            <div className="w-16 h-16 rounded-full alShadow overflow-hidden">
              <img className='-mt-1' src={`https://nitro-imager.habbo.gt/?figure=${props.account.look}&gesture=sml&direction=3&size=l&action=wave&headonly=0`} />
            </div>
            <li>
              {props.account !== null ? (
                  <div className="dropdown">
                    <button>
                      {props.account.username} 
                      <div className='fa fa-chevron-down'></div>
                    </button>
                    <div className="dropdown-menu">
                    <button>
                        <Link to={"/me"}>Home</Link>
                      </button>
                      <button>
                        <Link to={`/profile/${props.account.username}`}>Profil</Link>
                      </button>
                      <button>
                      <Link to={"/settings"}>Einstellungen</Link>
                      </button>
                    </div>
                  </div>
              ) : (
                <Link to="/">
                  <Link to="" className='navLinks rounded flex items-center gap-1'>
                    <i className="fas hidden lg:block fa-heart"></i> {props.account.username} 
                  </Link>
                </Link>
              )}
            </li>

            <li>
              <div className="dropdown">
                <button>
                  Community
                  <div className='fa fa-chevron-down'></div>
                </button>
                <div className="dropdown-menu">
                  <button>
                    <Link to={"/articles"}>Neuigkeiten</Link>
                  </button>
                  <button>
                    <Link to={"/team/all"}>Mitarbeiter</Link>
                  </button>
                  <button>
                  <Link to={"/ehrenamt/all"}>Assistenten</Link>
                  </button>
                  <button>
                  <Link to={"/vips"}>Premium</Link>
                  </button>
                  <button>
                  <Link to={"/community/0"}>Bestenliste</Link>
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div className="dropdown">
                <button>
                  Extras
                  <div className='fa fa-chevron-down'></div>
                </button>
                <div className="dropdown-menu">
                  <button>
                    <Link to={"/catalog"}>Preisliste</Link>
                  </button>
                  <button>
                    <Link to={"/badgecreator"}>Badge Creator</Link>
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div className="dropdown">
                <button>
                  <Link to={"/Regelwerk"}>Regelwerk</Link>
                </button>
              </div>
            </li>
            <li>
              <div className="dropdown">
                <button>
                Netzwerke
                  <div className='fa fa-chevron-down'></div>
                </button>
                <div className="dropdown-menu">
                  <button>
                    <Link to={"/catalog"}>X</Link>
                  </button>
                  <button>
                    <Link to={"/catalog"}>Discord</Link>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="HabboLogo px-3 md:px-0 mx-auto md:mx-0"></div>
      </div>
    </nav>
  )
}